import Image from 'next/image'
import T from 'prop-types'
import { useRef, useState } from 'react'

import BEMHelper from '../../helpers/bem'
import getTopic from '../../lib/topic'
import Button from '../Button'
import IllustrationGrid from '../IllustrationGrid'
import Link from '../Link'
import styles from './Styles.module.scss'

const bem = new BEMHelper(styles)

export function Entry({ slug, title, types, className, topic, titleNode, topicTheme, square }) {
  const [hover, setHover] = useState()
  const Title = titleNode || 'h2'

  const topicProps = topic?.slug ? getTopic(topicTheme) : null

  const style = topicProps && {
    '--color-background': topicProps.background,
    '--color-promoted': topicProps.promoted,
  }

  const hasLongWord = (title || '').split(' ').some((str) => str.length > 16)

  const handleHover = (value) => () => {
    setHover(value)
  }

  const hasTypes =
    types && ((Object.keys(types) || []).filter((key) => key !== 'intro') || []).length > 0

  return (
    <li {...bem('item', '', className)} style={style}>
      <Link
        href={`/undervisningsopplegg/${slug}`}
        {...bem('link', { square })}
        onMouseOver={handleHover(true)}
        onMouseOut={handleHover(false)}
      >
        <div {...bem('content')}>
          {topic && topic.title && <span {...bem('topic')}>{topic.title}</span>}

          {hasTypes && <IllustrationGrid types={types} {...bem('types')} hover={hover} />}

          {!hasTypes && topicProps?.illustration?.path && (
            <div {...bem('illustration')}>
              <div {...bem('image')}>
                <Image
                  src={topicProps.illustration.path}
                  alt=""
                  width={600}
                  height={600 / topicProps.illustration.aspect}
                />
              </div>
            </div>
          )}

          <Title {...bem('title', { 'long-word': hasLongWord })}>{title}</Title>
        </div>
      </Link>
    </li>
  )
}

export default function LessonEntry({ items = [], title, id }) {
  const [showMore, setShowMore] = useState(false)
  const entriesRef = useRef(null)

  if (!items?.length) {
    return null
  }

  const displayedItems = showMore ? items : items.slice(0, 3)
  const remainingItems = items.length - displayedItems.length

  return (
    <nav {...bem('')} id={id} ref={entriesRef}>
      {title && <h2 {...bem('main-title')}>{title}</h2>}

      <ul {...bem('list')}>
        {displayedItems.map((item) => (
          <Entry key={item._id || item.objectID || item.slug} {...item} />
        ))}
      </ul>

      {items.length > 3 && !showMore && (
        <Button onClick={() => setShowMore(true)} more>
          {remainingItems} flere
        </Button>
      )}
    </nav>
  )
}

const entryPropTypes = {
  _id: T.string,
  slug: T.string.isRequired,
  title: T.string.isRequired,
  types: T.object,
  entryType: T.string,
  objectID: T.string,
  purpose: T.string,
  preamble: T.string,
  count: T.number,
  className: T.string,
  topic: T.object,
  titleNode: T.string,
  topicTheme: T.object,
  square: T.bool,
}

LessonEntry.propTypes = {
  id: T.string,
  title: T.string,
  items: T.arrayOf(T.shape(entryPropTypes)),
}

Entry.propTypes = entryPropTypes
