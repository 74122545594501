/* eslint-disable @typescript-eslint/no-explicit-any */
import { createClient } from '@supabase/supabase-js'
import type { Database } from '../supabase.types'
import { uniq, without } from 'lodash'

const supabase = createClient<Database>(
  process.env.NEXT_PUBLIC_SUPABASE_API || 'https://ssteckrysccsjoyabozr.supabase.co',
  process.env.NEXT_PUBLIC_SUPABASE_API_KEY ||
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNzdGVja3J5c2Njc2pveWFib3pyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjI1MDAyMjUsImV4cCI6MjAzODA3NjIyNX0.yjKeqSoHaYKtx_FcFl5-MBbBrir-9XZwEy7ALlG2JCQ',
)

/**
 * Higher order function to get the user id and pass it to the wrapped function as the first argument
 */
const withEmail =
  <T, A extends any[]>(fn: (email: string, ...args: A) => Promise<T>) =>
  async (...args: A): Promise<T> => {
    const email = (await supabase.auth.getSession())?.data?.session?.user.email

    if (!email) {
      throw new Error('No user email found. Logged in?')
    }

    return fn(email, ...args)
  }

/**
 * Get course progress
 */
export async function getAllCourseProgress(): Promise<
  Record<
    string,
    {
      email: string
      progress: { courseId: string; completedModules: string[]; completed: boolean }[]
    }[]
  >
> {
  const users = await supabase
    .from('users')
    .select('*')
    .then(({ data }) => data)

  console.log(users)

  const courseProgress = await supabase
    .from('course_progress')
    .select('*')
    .then(({ data }) => data)

  console.log(courseProgress)

  const emails = uniq([
    ...(users?.map((user) => user.email) || []),
    ...(courseProgress?.map((p) => p.email) || []),
  ])

  return (
    emails?.reduce((acc, email) => {
      const user = users?.find((u) => u.email === email)

      const orgId = user?.org_id || 'not-connected'

      const courseIds = [
        ...(user?.course_ids || []),
        ...(courseProgress?.filter((p) => p.email === email).map((p) => p.course_id) || []),
      ]

      return {
        ...acc,
        [orgId]: [
          ...(acc[orgId] || []),
          {
            email: email,
            progress: courseIds?.map((courseId) => {
              const progress = courseProgress?.find(
                (p) => p.email === email && p.course_id === courseId,
              )

              return {
                courseId,
                completed_modules: progress?.completed_modules || [],
                completed: !!progress?.completed,
              }
            }),
          },
        ],
      }
    }, {}) || {}
  )
}

export const getStatistics = async () => {
  const { data } = await supabase.from('statistics').select('*')
  return data || []
}

export const getUser = withEmail(async (email) => {
  const { data } = await supabase.from('users').select('*').eq('email', email)
  return data?.[0] || null
})

export const setName = withEmail(async (email, name: string) =>
  supabase.from('users').upsert(
    { name, email },
    {
      onConflict: 'user_id',
    },
  ),
)

/**
 * Get course progress for all courses for the current user
 */
export const getUserCoursesProgress = withEmail(async (email) => {
  const result = await supabase.from('course_progress').select('*').eq('email', email)
  return result?.data || null
})

/**
 * Get course progress for the current user for a specific course
 */
export const getUserCourseProgress = withEmail(
  async (email, courseId: Database['public']['Tables']['course_progress']['Row']['course_id']) => {
    const result = await supabase
      .from('course_progress')
      .select('*')
      .eq('email', email)
      .eq('course_id', courseId)

    return result?.data?.[0] || null
  },
)

export const setCourseCompleted = withEmail(
  async (
    email,
    data: Pick<Database['public']['Tables']['course_progress']['Row'], 'course_id' | 'completed'>,
  ) =>
    supabase.from('course_progress').upsert(
      {
        email,
        updated_at: new Date().toISOString(),
        ...data,
      },
      { onConflict: 'course_id,email' },
    ),
)

export const resetCourseProgress = withEmail(
  async (email, courseId: Database['public']['Tables']['course_progress']['Row']['course_id']) => {
    await supabase.from('course_progress').delete().eq('email', email).eq('course_id', courseId)
  },
)

/**
 * Set course progress for the a course for the current user
 */
export const setCourseModuleCompleted = withEmail(
  async (
    email,
    data: {
      courseModuleId: NonNullable<
        Database['public']['Tables']['course_progress']['Row']['completed_modules']
      >[0]
      courseId: Database['public']['Tables']['course_progress']['Row']['course_id']
    },
    completed = true,
  ) => {
    const { data: existing } = await supabase
      .from('course_progress')
      .select('completed_modules')
      .eq('email', email)
      .eq('course_id', data.courseId)
      .single()

    // if no row exists and the module is not completed, there is nothing to do
    if (!existing && !completed) {
      return
    }

    // if no row exists and the module is completed, create a new row
    if (!existing) {
      return supabase.from('course_progress').insert({
        email,
        course_id: data.courseId,
        completed_modules: [data.courseModuleId],
      })
    }

    // if the row exists, update the completed_modules array by adding or removing the module id
    return supabase
      .from('course_progress')
      .update({
        completed_modules: uniq([
          ...without(existing.completed_modules || [], data.courseModuleId),
          ...(completed ? [data.courseModuleId] : []),
        ]),
        updated_at: new Date().toISOString(),
      })
      .match({
        email,
        course_id: data.courseId,
      })
  },
)

export default supabase
