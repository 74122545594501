/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */

import BEMHelper from '@/helpers/bem'
import styles from './Styles.module.scss'
const bem = BEMHelper(styles)

export const ICONS = {
  chevron: [
    [6, 9],
    <path
      d="M1.30002 8.74999L0.150024 7.59999L3.10003 4.64999L0.150024 1.64999L1.30002 0.549988L5.40002 4.64999L1.30002 8.74999Z"
      {...bem('fill')}
    />,
  ],
  close: [
    [9, 9],
    <>
      <path
        d="M1.15 8.20005L0 7.05005L2.95 4.10005L0 1.10005L1.15 4.87379e-05L5.25 4.10005L1.15 8.20005Z"
        {...bem('fill')}
      />
      <path d="M7.1 0L8.25 1.15L5.3 4.1L8.25 7.1L7.1 8.2L3 4.1L7.1 0Z" {...bem('fill')} />
    </>,
  ],
  x: [
    [13, 13],
    <>
      <polyline points="0.5,0.5 12.5,12.5" {...bem('line')} />
      <polyline points="0.5,12.5 12.5,0.5" {...bem('line')} />
    </>,
  ],
  dots: [
    [13, 3],
    <>
      <path
        d="M2.29995 0.299988C1.54995 0.299988 0.949951 0.849987 0.949951 1.64999C0.949951 2.44999 1.54995 2.99999 2.29995 2.99999C3.04995 2.99999 3.64995 2.44999 3.64995 1.64999C3.64995 0.849987 3.04995 0.299988 2.29995 0.299988Z"
        {...bem('fill')}
      />
      <path
        d="M10.7 0.299988C9.94995 0.299988 9.34995 0.849987 9.34995 1.64999C9.34995 2.44999 9.94995 2.99999 10.7 2.99999C11.45 2.99999 12.0499 2.44999 12.0499 1.64999C12.0499 0.849987 11.45 0.299988 10.7 0.299988Z"
        {...bem('fill')}
      />
      <path
        d="M6.49995 0.299988C5.74995 0.299988 5.14995 0.849987 5.14995 1.64999C5.14995 2.44999 5.74995 2.99999 6.49995 2.99999C7.24995 2.99999 7.84995 2.44999 7.84995 1.64999C7.84995 0.849987 7.24995 0.299988 6.49995 0.299988Z"
        {...bem('fill')}
      />
    </>,
  ],
  external: [
    [16, 15],
    <>
      <path
        d="M13.75 9.35001V13.35H2.44998V2.10001H6.49998V0.450012H0.849976V15H15.35V9.35001H13.75Z"
        {...bem('fill')}
      />
      <path
        d="M8.64997 8.30001L13.25 3.70001L15.35 5.75001V0.450012H10.05L12.1 2.55001L7.49997 7.15001L8.64997 8.30001Z"
        {...bem('fill')}
      />
    </>,
  ],
  upRight: [
    [16, 15],
    <path
      d="M8.64997 8.30001L13.25 3.70001L15.35 5.75001V0.450012H10.05L12.1 2.55001L7.49997 7.15001L8.64997 8.30001Z"
      {...bem('fill')}
    />,
  ],
  download: [
    [13, 15],
    <>
      <path d="M12.2 13.35H0.800049V14.95H12.2V13.35Z" {...bem('fill')} />
      <path
        d="M6.50005 11.75L10.2501 8.00001H7.30005V0.450012H5.70005V8.00001H2.75005L6.50005 11.75Z"
        {...bem('fill')}
      />
    </>,
  ],
  list: [
    [13, 7],
    <>
      <path d="M13 0.400024H0V2.00002H13V0.400024Z" {...bem('fill')} />
      <path d="M13 4.90002H0V6.50002H13V4.90002Z" {...bem('fill')} />
    </>,
  ],
  hamburger: [
    [27, 17],
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 1.80001H0V0.200012H27V1.80001ZM27 9.30001H0V7.70001H27V9.30001ZM27 16.8H0V15.2H27V16.8Z"
      {...bem('fill')}
    />,
  ],
  check: [
    [13, 10],
    <path
      d="M4.69998 9.34999L0.599976 5.19999L1.74998 4.04999L4.69998 7.04999L11.25 0.549988L12.35 1.64999L4.69998 9.34999Z"
      {...bem('fill')}
    />,
  ],
  progress100: [
    [17, 17],
    <>
      <circle cx="8.5" cy="8.5" r="7.5" {...bem('line', 'thin')} />
      <path d="M5.28577 8.33521L7.9583 11.0077L12.6089 6.35714" {...bem('line', 'thin')} />
    </>,
  ],
  progress75: [
    [17, 17],
    <>
      <circle
        cx="8.5"
        cy="8.5"
        r="7.5"
        transform="rotate(-90 8.5 8.5)"
        {...bem('line', ['thin', 'muted'])}
      />
      <path
        d="M8.5 1C12.6421 1 16 4.35786 16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5"
        {...bem('line', 'thin')}
      />
    </>,
  ],
  progress50: [
    [17, 17],
    <>
      <circle
        cx="8.5"
        cy="8.5"
        r="7.5"
        transform="rotate(-90 8.5 8.5)"
        {...bem('line', ['thin', 'muted'])}
      />
      <path
        d="M8.5 1C12.6421 1 16 4.35786 16 8.5C16 12.6421 12.6421 16 8.5 16"
        {...bem('line', 'thin')}
      />
    </>,
  ],
  progress25: [
    [17, 17],
    <>
      <circle
        cx="8.5"
        cy="8.5"
        r="7.5"
        transform="rotate(-90 8.5 8.5)"
        {...bem('line', ['thin', 'muted'])}
      />
      <path d="M8.5 1C12.6421 1 16 4.35786 16 8.5" {...bem('line', 'thin')} />
    </>,
  ],
  progress0: [
    [17, 17],
    <>
      <circle
        cx="8.5"
        cy="8.5"
        r="7.5"
        transform="rotate(-90 8.5 8.5)"
        {...bem('line', ['thin', 'muted'])}
      />
    </>,
  ],
  time: [
    [15, 15],
    <>
      <path
        d="M7.49998 0C3.54998 0 0.349976 3.2 0.349976 7.15C0.349976 11.1 3.54998 14.3 7.49998 14.3C11.45 14.3 14.65 11.1 14.65 7.15C14.65 3.2 11.4 0 7.49998 0ZM7.49998 12.65C4.44998 12.65 1.99998 10.2 1.99998 7.15C1.99998 4.1 4.44998 1.65 7.49998 1.65C10.55 1.65 13 4.1 13 7.15C13 10.2 10.55 12.65 7.49998 12.65Z"
        {...bem('fill')}
      />
      <path d="M8.29998 6.35V3.25H6.69998V7.95H11.4V6.35H8.29998Z" {...bem('fill')} />
    </>,
  ],
  search: [
    [16, 16],
    <path
      d="M9.75005 0.150024C6.75005 0.150024 4.35005 2.60002 4.35005 5.55002C4.35005 6.75003 4.75005 7.85003 5.40005 8.75003L0.0500488 14.15L1.20005 15.3L6.55005 9.95002C7.45005 10.6 8.55005 11 9.75005 11C12.7501 11 15.1501 8.55003 15.1501 5.60003C15.1501 2.65002 12.7501 0.150024 9.75005 0.150024ZM9.75005 9.40003C7.65005 9.40003 5.95005 7.70003 5.95005 5.60003C5.95005 3.50003 7.65005 1.80002 9.75005 1.80002C11.85 1.80002 13.55 3.50003 13.55 5.60003C13.55 7.70003 11.85 9.40003 9.75005 9.40003Z"
      {...bem('fill')}
    />,
  ],
  expand: [
    [15, 15],
    <>
      <path
        d="M10.35 9.44995L9.2 10.6L11.5 12.9L9.45 15H14.75V9.64995L12.65 11.75L10.35 9.44995Z"
        {...bem('fill')}
      />
      <path
        d="M10.35 5.99995L12.65 3.69995L14.75 5.74995V0.449951H9.45L11.5 2.54995L9.2 4.84995L10.35 5.99995Z"
        {...bem('fill')}
      />
      <path
        d="M5.55 0.449951H0.25V5.74995L2.3 3.69995L4.6 5.99995L5.75 4.84995L3.45 2.54995L5.55 0.449951Z"
        {...bem('fill')}
      />
      <path
        d="M4.6 9.44995L2.3 11.75L0.25 9.64995V15H5.55L3.45 12.9L5.75 10.6L4.6 9.44995Z"
        {...bem('fill')}
      />
    </>,
  ],
  collapse: [
    [15, 15],
    <>
      <path d="M3.65 3.90001H0.25V5.50001H5.3V0.450012H3.65V3.90001Z" {...bem('fill')} />
      <path d="M9.7 15H11.3V11.55H14.75V9.90001H9.7V15Z" {...bem('fill')} />
      <path d="M11.3 3.90001V0.450012H9.7V5.50001H14.75V3.90001H11.3Z" {...bem('fill')} />
      <path d="M0.25 11.55H3.65V15H5.3V9.90001H0.25V11.55Z" {...bem('fill')} />
    </>,
  ],
  play: [[14, 15], <path d="M13 7.49995L1.75 13.9951L1.75 1.00476L13 7.49995Z" {...bem('fill')} />],
  playCircle: [
    [17, 16],
    <>
      <path
        d="M8.49995 0.150024C4.34995 0.150024 0.949951 3.55002 0.949951 7.70003C0.949951 11.85 4.29995 15.3 8.49995 15.3C12.7 15.3 16.05 11.9 16.05 7.75003C16.05 3.60002 12.65 0.150024 8.49995 0.150024ZM8.49995 13.65C5.19995 13.65 2.54995 11 2.54995 7.70003C2.54995 4.40002 5.19995 1.80002 8.49995 1.80002C11.8 1.80002 14.45 4.45002 14.45 7.75003C14.45 11.05 11.75 13.65 8.49995 13.65Z"
        {...bem('fill')}
      />
      <path d="M6.94995 10.6L11.9 7.70002L6.94995 4.85002V10.6Z" {...bem('fill')} />
    </>,
  ],
  sound: [
    [29, 24],
    <>
      <path
        d="M17.54 8.53027C18.4774 9.46791 19.004 10.7395 19.004 12.0653C19.004 13.3911 18.4774 14.6626 17.54 15.6003M21.0698 5C22.9445 6.87528 23.9977 9.41836 23.9977 12.07C23.9977 14.7216 22.9445 17.2647 21.0698 19.14M13 5.07031L8 9.07031H4V15.0703H8L13 19.0703V5.07031Z"
        {...bem('line')}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </>,
  ],
  soundOff: [
    [29, 24],
    <>
      <path
        d="M24 9L18 15M18 9L24 15M13 5L8 9H4V15H8L13 19V5Z"
        {...bem('line')}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </>,
  ],
  menu: [
    [18, 14],
    <>
      <line y1="1.25" x2="18" y2="1.25" stroke-width="1.5" {...bem('line', 'thin')} />
      <line y1="7.25" x2="18" y2="7.25" stroke-width="1.5" {...bem('line', 'thin')} />
      <line y1="13.25" x2="18" y2="13.25" stroke-width="1.5" {...bem('line', 'thin')} />
    </>,
  ],
  dragHandle: [
    [18, 18],
    <>
      <circle cx="16" cy="2" r="2" {...bem('fill')} />
      <circle cx="16" cy="9" r="2" {...bem('fill')} />
      <circle cx="16" cy="16" r="2" {...bem('fill')} />
      <circle cx="9" cy="2" r="2" {...bem('fill')} />
      <circle cx="9" cy="9" r="2" {...bem('fill')} />
      <circle cx="9" cy="16" r="2" {...bem('fill')} />
      <circle cx="2" cy="2" r="2" {...bem('fill')} />
      <circle cx="2" cy="9" r="2" {...bem('fill')} />
      <circle cx="2" cy="16" r="2" {...bem('fill')} />
    </>,
  ],
}

interface IconProps {
  icon: string // replace 'string' with the actual type of 'icon'
  direction?: string // replace 'string' with the actual type of 'direction'
  className?: string
  title?: string
  // TODO: Remove?
  [key: string]: any // for the rest of the properties collected into 'props'
}

export default function Icon({
  icon,
  direction = undefined,
  className = undefined,
  title = undefined,
  ...props
}: IconProps) {
  if (!icon || !ICONS[icon]) {
    console.warn('Unsupporded icon', icon)
    return null
  }

  const [[width, height], content] = ICONS[icon]

  return (
    <svg
      {...props}
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
      {...bem('', [direction], className)}
    >
      {title && <title>{title}</title>}
      {content}
    </svg>
  )
}
